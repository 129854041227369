#scrollable-prevent-tabpanel-tabDetails{
    .tabDetails-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tracker-details-info-wrapper,
        .tracker-image {
            flex: 1;
        }
        .tracker-details-info-wrapper {
            padding: 15px;
        }

    }
}