#root{
    .launcherButton{
        background-color: $purple;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px; 
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 7px;            
        @media screen and (min-width: $media-mobile-size ){      
            letter-spacing: 1.25px;
            font-size: 14px;
            line-height: 16px;
            padding: 15px;
        }
    }
    .tracker-list-header{
        h1{
            color: rgba(0, 0, 0, 0.87);
            letter-spacing: 0.25px;
            font-size: 34px;
            line-height: 51px;
            @media screen and (min-width: $media-mobile-size ){  
                font-size: 27px;
                line-height: 30px;    
            }
        }
        
        .tracker-list-actions{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 15px;

            @media screen and (min-width: $media-mobile-size ){      
                flex-direction: row;
                &>*{
                    flex: 1;
                }
            }
        }
        .tracker-list-filters{
            flex: 1;
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            @media screen and (min-width: $media-mobile-size ){ 
                padding: 0px 15px;
                margin-top: 0px;
            }
            .tracker-filter-selector{
                display: flex;
                padding: 0px 15px;
                position: relative;
                min-width: 50%;
                width: 90%;
                background-color: white;
                @media screen and (min-width: $media-mobile-size ){      
                    min-width: 350px;
                }
            }
            .MuiListSubheader-root{
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.25px;
                color: rgba(0, 0, 0, 0.6);
                border-top: solid 1px #E5E5E5;
            }
        }
    }
}