.userActionList-wrapper{
    padding: 30px;
    @media screen and (max-width: $media-mobile-size){
        flex-direction: column;
        align-items: center;
        display: flex;
    }
    .userAction-item{
        text-decoration: none;
        width: fit-content;
        color: black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        svg{
            color: rgba(0, 0, 0, 0.54);
            font-size: 24px;
        }
        p{
            display: inline-block;
            margin-left: 25px;
        }
    }
    .userAction-item + .userAction-item{
        margin-top: 15px;
    }
}