.tabs-header{
  border-bottom: 1px solid $purple;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column-reverse;
  @media screen and (min-width: $media-mobile-size){      
    padding-bottom: 0px;  
    flex-direction: row;
  }
  .header-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    @media screen and (min-width: $media-mobile-size){      
      flex: 2;
    }
  }
  nav.pagination{
    margin-bottom: 15px;  
    @media screen and (min-width: $media-mobile-size){      
      margin-bottom: 0px;  
      flex: 1;
    }
  }
}

.tabs-filter-panel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  .tabs-buttons-options{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -15px;
    @media screen and (min-width: $media-mobile-size){      
      margin-top: 0px;
    }
    & > button{
      margin: 0 2px;
      flex: 1;
      margin-top: 15px;
      min-width: fit-content;
      width: 58px;
      max-width: unset;
      @media screen and (min-width: $media-mobile-size){      
        flex: unset;
        margin-top: 0px;
      }
    }
    & > button.active{
      background-color: #92278f45;
    }
  }
}
table.tableMap{
  thead, tbody{
    display: block;
    width: 100%;
  }
  tbody{
    overflow-y: auto;
    overflow-x: hidden;
    tr{
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }
  th,td{
    flex: 1;
    border: none;
    text-align: center;
    min-width: 75px;
  }
  tr{
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.selected{
      background-color: #92278f45;  
    }
  } 
}
.tabs-table{
  display: block;
  position: relative;
  overflow: auto;
}

.websocket-icon{
  background-color: $purple;
  border-radius: 50%;
  color:$white;
  padding: 5px;
}

