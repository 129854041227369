@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/SourceSansPro/SourceSansPro-Regular.ttf');
    // src: url('../fonts/futura-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/futura-regular.woff2') format('woff2'), url('../fonts/futura-regular.woff') format('woff'), url('../fonts/futura-regular.ttf') format('truetype'), url('../fonts/futura-regular.svg#futura-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/SourceSansPro/SourceSansPro-SemiBold.ttf');
    font-weight: bold;
    font-style: bold;
}