#root{
    #mainMenu-page{
        .menu-links-wrapper{
            padding: 45px 15px;
        }
        .panel-header{
            h1{
                min-height: 20px;
                letter-spacing: 0.25px;
            }
        }
    }
    .paper{
        width:220px;
        margin: 0 auto;
    }
}