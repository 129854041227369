#root {
    .logo-img{
        display: block;
        position: relative;
        &.large {
            width: 157;
            height: 157;
            margin:10;
        }
        &.small {
            width: auto;
            height: 75px;
        }
        &.smallLogo{
            width: 100%;
            height: auto;
            max-width: 200px;
        }
    }
    #register-login-wrapper{
        padding: 20px;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
        margin-top: calc(5vh);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
        @media screen and (max-width: $media-mobile-size){
            border-radius: unset;
            box-shadow: unset;
            padding: unset;
        }
        .MuiTabs-root {
            padding: 0px 24px;
            .MuiTab-root {
                min-width: 50%;
            }
        }
    }

    header.header-guest-wrapper{
        justify-content: center;
        display: flex;
        padding: 20px;
    }

    .auth-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        form{
            width: 100%;
            margin-top: 8px;
        }
        button[type="submit"]{
            height: 48px;
            margin: 20px auto;
            border-radius: 8px;
            text-transform: none;
        }
    }

    .login-wrapper {
        button[type="submit"]{
            margin-bottom: 16px;
        }
    }

    #forgot_password{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        color: #9E9E9E;
        display: block;
    }
}