.tableMap{
    margin-top:20px;
    .table-header{
        background-color: $gray50;
        color:$gray800;
    }
    .table-body{
        .table-row-body{
            &::nth-of-type(odd){
                background-color: $purple;
            }    
        }
    }

}