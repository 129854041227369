.tracker-image{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100%;
    width: auto;
    max-height: 150px;
    img{
        height: 100px;        
        max-height: inherit;
        width: auto;
        max-width: 150px;
        border-radius: 8px;
        &:focus {
            outline: none;
        }
        @media screen and (min-width: $media-mobile-size){   
            width: 100%;
        }
        display: block;
        position: relative;
    }
}