$text_color: rgba(0, 0, 0, 0.6);
$background_selected: lightgrey;

#root {
  .helios-table{

    &.table-container {
      width: 100%;
    }
    .paper{
      //background-color: #fff;
      width: 100%;
      margin-bottom: 16px;
      position: relative;
    }
    .table{
      border-collapse: separate;
      min-width: 750px;
      tbody{
        td{
          min-width: 25px;
          @media screen and (min-width: $media-desktop-size){    
            min-width: 75px;
          }
        }
      }
    }
    .visually-hidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1;
      margin: -1;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 20;
      width: 1;
    }
    .loading {
      background-color: rgba(0,0,0,.1);
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        color: $purple;
      }
    }
    .pagination {
      @media screen and (max-width: $media-mobile-size){        
          width: 100%;
          display: flex;
          justify-content: center;
          .MuiTablePagination-caption {
            display: none;
        }
      }
    }
    .MuiTableCell-body {
      color: $text_color
    }
    .MuiTableCell-head {
      color: $black;
      font-weight: 500;

      .MuiTableSortLabel-active {
        color: inherit;
      }
    }
    .MuiTableCell-root {
      border: none;
      white-space: nowrap;
    }
    .Mui-selected {
      background-color: $background_selected;
      .sticky-column {
        background-color: $background_selected;
      }
    }
    .Mui-checked {
      color: grey;
    }
    .sticky-column {
      position: sticky;
      left: 0;
      background-color: #fff;
      z-index: 1;
      +.sticky-column {
        border-right: 1px solid $background_selected;
        left: 46px;
      }
    }
    .toolbar {
      padding-left: 16px;
      padding-right: 8px;
      &.highlight {
        background-color: $background_selected;
      }
      .title {
        flex: 1 1 100%;
        font-size: 16px;
      }
    }
  }
}