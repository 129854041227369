#helios-body {
  .text-with-icon-wrapper{
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    svg{
      height: auto;
    }
  }
  .text-with-icon-wrapper{
    svg{
      width: 20px ;
      margin-right: 15px ;
    }
  }
}

#root{
  .dashboard-header{
    h1{
      color: rgba(0, 0, 0, 0.87);
      font-size:34px;
      .month{
        text-transform: uppercase;
        margin-left: 15px;
      }
      .year{
        color: rgb(132, 125, 125);
        margin-left: 7px;
      }
    }
    h5{
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .panel-wrapper{
    background-color: $panel-background-color;
    border-radius: 5px;
    padding: 15px;
  }

  .last-viewed-wrapper{
    display: flex;
    flex-direction: column;
    h3{
      margin: 0px;
    }
    .last-tracker-viewed-info-wrapper{
      display: flex;
      padding: 15px 0px;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      @media screen and (min-width: $media-desktop-size){
        flex-direction: row;
      }

      .block-info{
        flex:1;
        width: 100%;
        min-width: 100%;
        @media screen and (min-width: $media-desktop-size){
          width: 50%;
          min-width: auto;
          margin-top: 0px;
        }
      }
      .block-tacker-info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        @media screen and (min-width: $media-desktop-size){
          flex-direction: row;
        }
      }
      .tracker-info{
        flex: 1;
        width: 100%;
        margin-top: 15px;
        @media screen and (min-width: $media-desktop-size){
          width: 50%;
          margin-top: 0px;
        }
        p{
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.15px;
          color: $letter-color1;
          width: 50%;
          display: inline-block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (min-width: $media-desktop-size){
            width: 100%;
            margin-top: 0px;
          }
          span{
            display: block;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: $letter-color3;
            text-transform: uppercase;
          }
        }
        p+p{
          margin-top: 7px;
        }
      }

      .block-tracker-event{
        margin-top: 15px;
        @media screen and (min-width: $media-desktop-size){
          margin-top: 0px;
        }
        h5{
          text-transform: uppercase;
          font-size: 14px;
          font-weight: bold;
          color: $letter-color3;
          margin: 0px;
          margin-bottom: 3px;
        }
        .event-time{
            margin: 0px;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.15px;
            color: $letter-color1;
            span{
                margin-right: 5px;
            }
        }
        .event-tracket-status{
          font-family: Roboto;
          font-style: normal;
          font-weight: 600;
          color: #C62828;
          margin: 15px 0px;
          svg{
            height: 29px;
            width: auto;
          }
        }
      }
    }
  }

  .general-info-wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;
    @media screen and (min-width: $media-mobile-size){
      margin: 0px -15px;
    }
    .block-info{
      flex: 1;
      width: 100%;
      margin-top: 25px;
      @media screen and (min-width: $media-mobile-size){
        width: 50%;
        margin: 0px 15px;
      }
    }
  }
}