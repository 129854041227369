#scrollable-prevent-tabpanel-tabMap{
  .heliosMap-wrapper{
      position: relative;
      & > div{
        //height: 50vh;
        //width: 100%;
        position: relative;
      }
  }
  
  .tabs-size{
    padding-top: 0px;
    .MuiTabs-fixed{
      .MuiTab-root {
        color: $purple;
        background-color: $white;
        .tab-icons{
          border: 2px solid $purple;
          border-radius: 2px;
        }
        &.Mui-selected{
          color: $white;
          background-color: $purple;
          .tab-icons{
            border: 2px solid $white;
          }
        }
      }
      .MuiTabs-indicator{
        display: none;
      }
    }
    .tabs-container{
      .boxes{
        .MuiPaper-root{
          border: 1px solid #e0e0e0;
          background-color: #f0f0f0;
        }
      }
    }
  }
}