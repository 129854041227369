.interval-mode-input {
  width: 100%;  
  &> .MuiTextField-root{
    width: 100%;
    margin: 0px;
    margin-top: 15px;  
    @media screen and (min-width: $media-mobile-size ){      
      width: 80%;
    }
    .MuiInputBase-root{
      fieldset{
        border-color: $borderInputColor;
      }
      &.active{
        fieldset{
          border-color: $borderActiveInputColor;
        }
      }
    }
    .MuiInputBase-input{
      padding: 10px;
      color: $spanColor;
      font-family: $textFont;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      cursor: pointer;
      &:focus{
        background-color: transparent;
      }
    }
  }
  &> .description-message{
    font-family: $textFont;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 12px;
    display: block;
    color: $spanColor;
    font-style: italic;
    &.error-message{
      color: red;
    }
  }
}
#interval-mode-popover{
  .MuiPaper-root{
    min-width: 250px;
  }
  .interval-mode-inputs-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .input-wrapper input,
    & > .separator span {
      color: $textColor;
      font-family: $textFont;
      font-size: 48px;
    }
    .separator{
      display: flex;
      align-items: center;
      margin: 0px 10px;
      span{
        height: 33px;
        line-height: 25px;
        margin-top: -15px;
      }
    }
    .input-wrapper{
      display: flex;
      flex-direction: column;
      .MuiInputBase-root{
        color: $textColor;
        border-color: $borderInputColor;
        & > input{
          letter-spacing: 0;
          line-height: 60px;
          text-align: center;
          max-width: 60px;
          padding: 25px;
          border: 1px solid $borderInputColor;
          border-radius: 8px;
          //remove the arrow in number input
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &:active,
          &:focus{
            border-color: $borderActiveInputColor;
          }
          -moz-appearance: textfield;
        }
      }
      span{
        color: $spanColor;
        font-family: $textFont;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
}