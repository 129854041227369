.calendar-mode-input{
  width: 100%;
  &> button {
    color: $purpleComponentColor;
    font-family: $textFont;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    background-color: transparent;
    text-transform: none;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
}