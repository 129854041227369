#root{
  .trackers-tabLogs-wrapper {
    .helios-table {
      padding: 0px;
    }
    .helios-table-container {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}