.details-trackerList-wrapper{
  .helios-table {
    padding: 0px !important;
    table{
      display: block;
      max-height: 500px;
      tr {
        cursor: pointer;
      }
    }
  }
}