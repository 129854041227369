#root{
    .link-with-icon{
        text-decoration: none;
        margin: 14px 0px;
        display: block;
        .text-with-icon-wrapper{
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: rgba(0, 0, 0, 0.87);
            margin: 0px;
            svg{
                width: 24px;
                margin-right: 35px;
            }
        }
        .link-with-icon + .link-with-icon{
            margin: 30px 0px 0px 0px;
        }
    }
}
.text-with-title-wrapper{
    display: block;
    margin: 0px;
    margin-bottom: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.15px;
    span{
        display: block;
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 5px;
    }
}

.popover-trigger-wrapper{
    padding: 0;
    border: none;
    margin: auto;
    cursor: pointer;
    background: no-repeat;
    &:active,
    &:focus {
        border: none;
        outline: none;
    }
}

.popover-wrapper-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    .MuiPaper-root.MuiPopover-paper{
        // is required because we have to use a number in Popover component (anchorPosition)
        top: unset !important; 
        left: unset !important;
        max-width: fit-content;
        border-radius: 8px;
        & > * {
            display: block;
            max-width: 50vw;
            @media screen and (max-width: $media-mobile-size){
                max-width: 80vw;
            }
        }
    }
}

.loading.spinner{
    display: flex;
    color: $purple;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 100%;
}