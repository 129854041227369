.tracker-settings-wrapper{
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: $media-mobile-size ){      
    margin-left: -25px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .box-settings-wrapper{
    margin-bottom: 25px;
    width: 100%;
    height: max-content;
    max-height: fit-content;
    @media screen and (min-width: $media-mobile-size ){      
      margin-left: 25px;
      width: 50%;
    }
  }
}
$textColor: #323547;
$spanColor: #4F5572;
$purpleComponentColor: #92278F;
$borderInputColor:  rgba(146,159,180,0.25);;
$borderActiveInputColor: #1F253B;
$textFont: "Source Sans Pro";
.box-settings-wrapper{
  .MuiCardContent-root,
  .MuiCardHeader-root {
    padding: 25px;
  }
  .MuiCardContent-root {
    padding-top: 10px;
  }
  .MuiCardHeader-content{
    .MuiCardHeader-title{
      color:$textColor;
      font-family: $textFont;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;
    }
    .MuiCardHeader-subheader{
      color:$textColor;
      font-family: $textFont;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .tracker-settings-action-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 35px 25px;
    padding-top: 10px;

    button{
      color: $purpleComponentColor;
      font-family: $textFont;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      background-color: transparent;
      text-transform: capitalize;
      cursor: pointer;
      &.submit{
        color: white;
        background-color: $purpleComponentColor;
        border-radius: 8px;
        &:disabled{
          background-color: #E4E7EC;
          color: #929FB4;
        }
      }
    }
    button + button {
      margin-left: 15px;
    }
  }

  .MuiDivider-root{
    margin: 30px 0px;
  }

  .setting-element{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 20px;
    margin: 15px 0px;
    .title{
      color: $textColor;
      font-family: $textFont;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (min-width: $media-mobile-size ){      
        width: 50%;
      }
      .MuiSvgIcon-root{
        margin-left: 7px;
        height: 14px;
        width: 14px;
      }
      .updated-field-notification{
        margin: 0 10px 0 0;
        color: $purpleComponentColor;
        &.MuiSvgIcon-root{
          height: 10px;
          width: 10px;
        }
      }
    }
    p{
      margin: 0px;
    }
    .text-value{
      color: $spanColor;
      font-family: $textFont;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      width: 50%;
    }

    // Switch components
    .MuiSwitch-root{
      .MuiButtonBase-root{
        &.Mui-disabled{
          &.Mui-checked{
            .MuiSwitch-thumb{
              background-color: $purpleComponentColor;
            }
            & ~ .MuiSwitch-track{
              background-color: $purpleComponentColor;
            }
          }
        }
        &.Mui-checked{
          .MuiSwitch-thumb{
            background-color: $purpleComponentColor;
          }
          & ~ .MuiSwitch-track{
            background-color: $purpleComponentColor;
          }
        }
      }
    }
  }

  // Mode Selector
  .settings-mode-select-wrapper {
    width: 100%;
    .settings-mode-select{
      width: 100%;
      @media screen and (min-width: $media-mobile-size ){      
        width: 80%;
      }
      margin: 15px 0px;
      border: 1px solid $borderInputColor;
      border-radius: 8px;
      background-color: #FFFFFF;
      .MuiSelect-select{
        padding: 10px;
        color: $spanColor;
        font-family: $textFont;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        &:focus{
          background-color: transparent;
        }
      }
      &.MuiInput-underline:hover:not(.Mui-disabled):before{
        border-bottom: inherit;
      }
      &:before,
      &:after{
        content: none;
      }
    }
  }

  .settings-mode-content{
    margin-top: 10px;
    width: 100%;
  }
  
  &.static{
    .settings-mode-content{
      margin-top: 24px;
      width: 100%;
    }
  }
}
@import 'Elements/intervalMode';
@import 'Elements/calendarMode';