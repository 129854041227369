$purple:#92278F;
$green: #1F9FB0;
$white: #ffffff;
$blue:#1976D2;
$black: #000000;
$gray88: #888888;
$grayF2: #F2F2F2;
$gray50: #F0F0F0;
$gray800:#1A1A1A;
$softPink:#FFD2D5;

$letter-color1: #2aa1b0;
$letter-color2: #333333;
$letter-color3: #666666;
$letter-color4: #10584f;
$letter-color5: #118494;
$letter-color6: #C62828;
$letter-color7: #9E9E9E;

$panel-background-color: #f7f7f7;

$background-color1: #d2efec;
$background-color2: #ffffff;

// modal buttons
$modal-button-submit-color: white;
$modal-button-submit-bg: #1E88E5;

$modal-button-cancel-color: #1E88E5;
$modal-button-cancel-bg: transparent;
$modal-button-cancel-border: #1E88E5;

$modal-button-disabled-color: rgba(0, 0, 0, 0.26);
$modal-button-disabled-bg: rgba(0, 0, 0, 0.12);

$on-hover-button-purple: #BA32B6;
