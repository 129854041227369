@import 'colors';
@import 'fonts';

$media-mobile-size: 950px;
$media-desktop-size: 1025px;

html {
    &::-webkit-scrollbar {
        display: none;
    }
}

.MuiFormHelperText-root{
    &.error{
        color: red;
    }
}

.modal-dialog {
    .modal-content{
        background-color: #fff;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
        padding: 16px 32px 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 80%;
        width: 80%;
        height: auto;
        overflow:hidden;
        border-radius: 8px;
        .header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #D7D5D5;
            margin:10px 0;
            .close-btn {
                padding: 0px;
                margin-left: auto;
                &:hover{background-color: transparent;}
            }
        }
        .body{
            display: flex;
            max-height: 75vh;
            overflow-y: scroll;
            flex-direction: column;
            &::-webkit-scrollbar {
                display: none;
            }
            .buttonsModal{
                display: flex;
                justify-content: space-between;
                align-items: center;
                button{
                    min-width: 35%;
                }
            }
            .loading{
                min-height: 35vh;
                min-width: 50vh;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    color: $purple;
                }
            }
            button.MuiButtonBase-root {
                &.submit {
                    color: $modal-button-submit-color;
                    background-color: $modal-button-submit-bg;
                }
                &.cancel {
                    color: $modal-button-cancel-color;
                    background-color: $modal-button-cancel-bg;
                    border-color: $modal-button-cancel-border;
                }
                &.Mui-disabled {
                    color: $modal-button-disabled-color;
                    box-shadow: none;
                    background-color: $modal-button-disabled-bg;
                }
            }
        }
        &.new-folder,
        &.share-folder,
        &.delete-folder,
        &.rename-folder,
        &.assign-trackers,
        &.change-password,
        &.change-timezone,
        &.unassign-trackers,
        &.reset-password {
            max-width: 400px;
            min-width: 300px;
        }
        &:focus {
            outline: none;
        }

        &.change-timezone{
            .MuiAutocomplete-root{
                margin-bottom: 25px;
            }
            .MuiGridListTile-tile{
                button.MuiButtonBase-root{
                    .MuiCircularProgress-root{
                        height: 10px !important;
                        width: 10px !important;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

#root {
    .panel-header{
        background-color: $gray50;
        padding: 20px 15px;
        .panel-content{
            margin: 0 auto;
        }
    }
    .content-wrapper{
        padding: 30px;
        // background-color: $white;
    }
}
h1,h2,h3,h4,h5,h6{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
}

h1{
    font-size: 30px;
    line-height: 38px;
}
h2{
    font-size: 24px;
    line-height: 28px;
}
h3, p{
    font-size: 16px;
    line-height: 20px;
}
h4{
    font-size: 14px;
    line-height: 18px;
}
h5, h6{
    font-size: 12px;
    line-height: 16px;
}

@import 'login';
@import 'profile';
@import 'dashboard';
@import 'elements';
@import 'menu';
@import 'trackers';
@import 'tables';
@import 'tabsTrackerDetail';
@import 'detailsPage';
@import 'tableMap';
@import 'eventModal';
@import 'Settings/index';
@import 'InspectTrackers/index';
