.modalTitle{
    color:$gray88;
}
.info, .sats, .speed, .location, .course{
    margin:0px 0 20px 0;
    padding:20px;
    height: auto;
    min-height: 110px;
    
}
.box, .box-sats{
    text-align: center;
    padding: 5px;
}
.box{
    background-color: $grayF2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;


}
.box-sats{
    background-color: $softPink;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    
}
.MuiButton-containedPrimary.blueBoxViewMap{
    padding:30px 20px;
    margin:0 auto;
    width: 100%;
    background-color: #92278F;
    &:hover {
      background-color: #BA32B6;
    }
}